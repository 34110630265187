import {
  DETAILS_DATA_SUCCESS,
  FETCH_DETAILS_DATA_ERROR,
  GET_USER_ROLES,
  GET_USER_ROLES_SUCCESS,
  UPDATE_TASK_ROLES,
  UPDATE_TASK_ROLES_SUCCESS,
  GET_PREVIOUS_TASKS_SUCCESS
} from "./detailsConstant";
import _ from 'lodash';
import mockdata from '../crew/MockPreviousTask.json'

 const initialState = {
  detailsData: [],
  responseStatusCode: -1,
  isProgress: false,
  apiError: null,
  offline: null,
  taskRoles : {},
  setRolesCount:0,
  previousTasks:{},
  previousTasksArr: [],
};

const detailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DETAILS_DATA_SUCCESS: {
      return {
        ...state,
        detailsData: _.uniqBy([action.payload, ...state.detailsData], "taskId")
      };
    }
    case FETCH_DETAILS_DATA_ERROR: {
      return {
        ...state,
        apiError: action.payload
      }
    }
    case UPDATE_TASK_ROLES : {
      return {
        ...state,
        updateData: action.updateData
      }
    }
    case UPDATE_TASK_ROLES_SUCCESS : {
      return {
        ...state,
        updateData: action.updateData,
        setRolesCount: state.setRolesCount + 1
      }
    }
    case GET_PREVIOUS_TASKS_SUCCESS: {
      console.log("DETAILS DATA::: "+ state.detailsData);
       return {
          ...state,
          previousTasks: action.response,
          previousTasksArr: _.uniqBy([...state.previousTasksArr, action.response], "userId")
       }
  }
    default: {
      return state;
    }
  }
};

export default detailsReducer;
