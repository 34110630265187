import React, { Fragment, useState, useEffect } from "react";
import CrewComponent from "../crew/CrewComponent";
import CommentComponent from "../comment/CommentComponent";
import "./DetailsComponent.scss";
import SetRoles2 from "./setRoles2";

const DetailsComponent = (props) => {
  const [modalShowFlag, setModalShowFlag] = useState(false);
  const [isRolesButtonShowable, setIsRolesButonShowable] = useState(false);
  const showHideModal = (val) => {
    if (isRolesButtonShowable == false) {
      alert("No crew members available to assign them roles.");
      return;
    } else {
      setModalShowFlag(
        val != null && typeof val == "boolean" ? val : !modalShowFlag
      );
    }
  };
     
  useEffect(() => {
    var fscrewlst = props.fsCrewList

    if (props.fsCrewList != null) {

      props.fsCrewList.map((item) => {
        item.previousTask = props.previousTask
      })
      // console.log("MyFlights-fscrewlist:: ", JSON.stringify(props.fsCrewList));

      let flag = true;
      let len = props.fsCrewList.length;
      for (let i = 0; i < len; i++) {
        if (props.fsCrewList[i].ackRequired == false) {
          flag = false;
          break;
        }
      }
      if (flag) {
        setIsRolesButonShowable(false); 
      } else {
        setIsRolesButonShowable(true);
      }
    }
  }, [props.fsCrewList]);

  useEffect(() => {
    var csCrewList = props.csCrewList
    if (props.csCrewList !== null && props.csCrewList !== undefined) {
      props.csCrewList.map((item) => {
        item.previousTask = props.previousTask
      })
      // console.log("MyFlights-csCrewList:: ", JSON.stringify(props.csCrewList));

    }
  }, [props.csCrewList]);

  useEffect(() => {
    // console.log("MyFlights_PREVVV");

    if (props.previousTask !== null && props.previousTask !== undefined) {
      // console.log("MyFlights_PREVVV1");

    }
  }, [props.previousTask]);


  return (
    <Fragment>
      <div className="details">
        {props.endLocation && props.startLocation && props.endLocation !== props.startLocation &&
          <div className="endLocation">
          <div className="locationheader mb-3">Task End Location</div>
          <div className="locationheader mb-3">{props.endLocation}</div>
          </div>
        }
        <div className="header1 mb-3">Task Details</div>
        <div className="header2">Crew</div>
        {props.selectedTaskBar != null &&
          props.selectedTaskBar.value == "home" &&
          !props.loginAsData.userId &&
          props.crewChief == true &&
          isRolesButtonShowable == true &&
          props.ccRoleEnabled == true && (
            <button
              onClick={showHideModal}
              style={{
                marginLeft: "22px",
                marginBottom: "6px",
              }}
              className="rolesBtn rolesBtnSubmit btn btn-primary"
            >
              Roles
            </button>
          )}

        {modalShowFlag && (
          <SetRoles2
            modalShowFlag={modalShowFlag}
            showHideModal={showHideModal}
            fsCrewList={
              props.fsCrewList != null && props.fsCrewList.length > 0
                ? props.fsCrewList.filter((el) => el.taskRoleEligible == true)
                : []
            }
            superprops={props}
            action={props.action}
            taskRoles={props.taskRoles}
            fetchDetailsParams={props.fetchDetailsParams}
            callForDetailsAction={props.callForDetailsAction}
            setRolesCount={props.setRolesCount}
            callForAllUserDetails={props.callForAllUserDetails}
            allowedTaskRoles={props.allowedTaskRoles}
          />
        )}
        {props.myFlights && props.responsibility === "CS" ? (
          <div className="row mx-0">
            <div className="col-sm-12">
              <CrewComponent
                crewList={props.csCrewList}
                crewInfo={"care"}
                empName={props.empName}
                showNameFlag={props.showNameFlag}
                isCrewChief={props.crewChief}
                role={props.decodedToken.SM_ROLE}
                shiftInfoList={null}
                fetchDetailsParams={props.fetchDetailsParams}
                isSingleAgent={props.isSingleAgent}
              />
            </div>
          </div>
        ) : props.myFlights && props.responsibility === "FS" ? (
          <div className="row mx-0">
            <div className="col-sm-12">
              <CrewComponent
                crewList={props.fsCrewList}
                crewInfo={"operations"}
                empName={props.empName}
                showNameFlag={props.showNameFlag}
                isCrewChief={props.crewChief}
                role={props.decodedToken.SM_ROLE}
                shiftInfoList={props.shiftInfoList}
                fetchDetailsParams={props.fetchDetailsParams}
                isSingleAgent={props.isSingleAgent}
              />
            </div>
          </div>
        ) : (
          <div className="row mx-0">
            <div className="col-sm-6">
              <CrewComponent
                crewList={props.csCrewList}
                crewInfo={"care"}
                empName={props.empName}
                showNameFlag={props.showNameFlag}
                isCrewChief={props.crewChief}
                role={props.decodedToken.SM_ROLE}
                shiftInfoList={null}
                fetchDetailsParams={props.fetchDetailsParams}
                isSingleAgent={props.isSingleAgent}
              />
            </div>
            <div className="col-sm-6">
              <CrewComponent
                crewList={props.fsCrewList}
                crewInfo={"operations"}
                empName={props.empName}
                showNameFlag={props.showNameFlag}
                isCrewChief={props.crewChief}
                role={props.decodedToken.SM_ROLE}
                shiftInfoList={props.shiftInfoList}
                fetchDetailsParams={props.fetchDetailsParams}
                isSingleAgent={props.isSingleAgent}
              />
            </div>
          </div>
        )}
        <div className="row mx-0">
          <div className="col-sm-12 ">
            <hr />
          </div>
        </div>
        {!props.myFlights && (
          <div className="row mb-2 mx-0">
            <div className="col-sm-12">
              <CommentComponent commentsList={props.commentsList} />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DetailsComponent;
