import axios from 'axios';
import * as ApiEndPoints from './ApiEndPoints';

const cloudInstance = axios.create({
    baseURL: ApiEndPoints.CLOUD_BASE_URL
});


//azureInstance.defaults.headers['Access-Control-Allow-Origin'] = '*';
cloudInstance.defaults.headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
cloudInstance.defaults.headers['Access-Control-Allow-Methods'] = "GET, POST, PATCH, PUT, DELETE, OPTIONS'";

cloudInstance.defaults.headers.post['Content-Type'] = 'application/json';
cloudInstance.defaults.timeout = 30000;

cloudInstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});
 
export default cloudInstance;
