import { put, call, delay } from 'redux-saga/effects';
import * as actions from '../../modules/scorecard/scorecardAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';
import { getTipData, getScorecardData, getScoreDashboard, getEmployeeFlightDashboard } from '../../utils';
import * as erroractions from '../../modules/error/ErrorAction';

export function* getTipDataDownload (data){
    for(let i = 0; i < 3; i++) {
        try {
            const response = yield call(
                getTipData, 
                data.metricsType,
                data.station
            )
            if(response.data.errorResponseDTO.errorCode === 0 || response.data.errorResponseDTO.errorCode=== 1023){
                yield put(actions.getTipDataSuccess(response.data));
            } else {
                yield put(erroractions.showErrorAction({ errorCode: 1500, errorMessage: 'There was a problem getting your Performance Dashboard. Please try again later.'}))
            }
            break;
        }
        catch (error) {
            yield put(erroractions.removeLoaderAction());
            yield put(erroractions.showErrorAction({ errorCode: 1500, errorMessage: 'There was a problem getting your Performance Dashboard. Please try again later.'}));
            break;
        }
    }
}

export function* getScorecardReport ({data}) {
    for(let i = 0; i < 3; i++) {
        try {
            yield put(erroractions.showLoaderAction());
            const response = yield call(
                getScorecardData, 
                data.adminEmployeeId,
                data.employeeId,
                data.stationCode
            )
            yield put(erroractions.removeLoaderAction());
            if(response.data.errorResponseDTO.errorCode === 0 || response.data.errorResponseDTO.errorCode=== 1023){
                yield put(actions.getScorecardReportSuccess(response.data))
            } else {
                yield put(erroractions.showErrorAction({ errorCode: 1500, errorMessage: 'There was a problem getting your Performance Dashboard. Please try again later.'}))
            }
            break;
        }
    
        catch (error) {
            yield put(erroractions.removeLoaderAction());
            if(i < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                yield put(erroractions.showErrorAction({ errorCode: 1500, errorMessage: 'There was a problem getting your Performance Dashboard. Please try again later.'}));
                break;
            }
        }
    }
}

export function* getUserScore ({data}) {
    for(let i = 0; i < 3; i++) {
        try {
            yield put(erroractions.showLoaderAction());
            const response = yield call(
                getScoreDashboard,
                data.employeeId,
                data.stationCode
            )
            yield put(erroractions.removeLoaderAction());
            if(response.data.errorResponseDTO.errorCode === 0 || response.data.errorResponseDTO.errorCode=== 1023){
                yield put(actions.getScoreSuccess(response.data))
            } else {
                yield put(erroractions.showErrorAction({ errorCode: 1500, errorMessage: 'There was a problem getting your Performance Dashboard. Please try again later.'}));
            }
            break;
        }
    
        catch (error) {
            yield put(erroractions.removeLoaderAction());
            if(i < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                yield put(erroractions.showErrorAction({ errorCode: 1500, errorMessage: 'There was a problem getting your Performance Dashboard. Please try again later.'}));
                break;
            }
        }
    }
}

export function* getFlightDetails ({data}) {
    for(let i = 0; i < 3; i++) {
        try {
            yield put(erroractions.showLoaderAction());
            const response = yield call(
                getEmployeeFlightDashboard,
                data.userId,
                data.station,
                data.flightDate,
                data.workArea
            )
            yield put(erroractions.removeLoaderAction());
            if(response.data.errorResponseDTO.errorCode === 0 || response.data.errorResponseDTO.errorCode=== 1023){
                yield put(actions.getFlightDetailsSuccess(response.data))
            } else {
                yield put(actions.getFlightDetailsError(response.data))
                break;
            }
        }
        catch (error) {
            yield put(erroractions.removeLoaderAction());
            yield put(actions.getFlightDetailsError(true))
            yield put(erroractions.showErrorAction({ errorCode: 1500, errorMessage: 'There was a problem getting your Performance Dashboard. Please try again later.'}))
            break;
        }
    }
}
