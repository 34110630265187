import { put, call, delay } from 'redux-saga/effects';
import { getCrewInfo, setTaskRoles, getUserRoles, getPreviousTasks } from '../../utils';
import * as actions from '../../modules/details/detailsAction';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';
import mockdata from '../../modules/crew/MockData.json'

 export function* fetchCrewDetails(action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getCrewInfo, action.companyCode, action.stationCode, action.flightIds, action.taskId);
            response.data.errorResponseDTO.errorCode === 0 ? 
                yield put(actions.fetchDetailsDataSuccess(response.data, action.taskId))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
            break;
        }
        catch (error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* updateTaskRolesFn(action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            /**
             * userId,
             * companyCode,
             * stationCode,
             * roles
             */
            const response = yield call(setTaskRoles, action.data);
            if(response.data.errorResponseDTO.errorCode === 0) {
                yield put(actions.updateTaskRolesSuccess(response.data, action.userId, action.companyCode, action.stationCode, action.roles))
            } else {
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
            }
            break;
        }
        catch (error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* showPopupErrorUi(data) {
    yield put(erroractions.showErrorAction({
        ...data.err
    }))
}


export function* fetchPreviousTasks (action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getPreviousTasks, action.employeeId, action.taskId, action.stationCode, action.companyCode);
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.fetchPreviousTasksSuccess(response.data.taskData))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break
        }
        catch(error){
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}