import { put, call, all, delay } from "redux-saga/effects";
import * as actions from '../../modules/myFlights/MyFlightsAction';
import { getFlights} from '../../utils';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';
export function* searchFlights(action) {
    for (let retryCount = 0; retryCount < 3; retryCount++) {
      try {
        const response = yield call(
          getFlights,
          action.stationCode,
          action.department,
          action.gateName,
        );
        response.data.errorResponseDTO.errorCode === 0
        ? yield put(actions.fetchFlightsDataSuccess(response.data))
        : yield all([
            yield put(actions.fetchFlightsDataSuccess(response.data)),
            yield put(
              erroractions.showErrorAction(response.data.errorResponseDTO)
            ),
          ]);
        break;
      } catch (error) {
        if (
          retryCount < 2 &&
          (JSON.stringify(error.code) === '"ECONNABORTED"' ||
            JSON.stringify(error.message) === '"Network Error"')
        ) {
          yield delay(5000);
        } else {
          commonError(error);
          break;
        }
      }
    }
  }
