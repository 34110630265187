import { put, call, delay } from 'redux-saga/effects';
import * as actions from '../../modules/authentication/AuthenticationAction';
import * as scorecardActions from '../../modules/scorecard/scorecardAction';
import qs from 'qs'
import axios from 'axios';
import jwt from 'jwt-decode';
import { openTourOnLogIn } from '../../modules/task/taskAndShiftAction';
import { setAuthorizationToken, initialDataDownload, logOff } from '../../utils';
import resources from  '../../utils/resources/resources.json';
import * as erroractions from '../../modules/error/ErrorAction';
import {trimUserId} from '../../modules/authentication/AuthenticationHandler';

const getAccessToken = (options) => {
    return axios(options)
}

  export function* fetchAccessToken(params) {
    if(params.tokenData === null) {
        try {
            const config = { 
                method: 'post',
                url: resources.OauthUrl,
                data: qs.stringify({
                  grant_type: 'authorization_code', 
                  code:params.code, 
                  client_id: resources.client_id,
                  redirect_uri:resources.redirectUri
                }),
                headers: {
                  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            }

            let users = yield call(getAccessToken, config);
            if(users.status !== 200) {
                clearAuthResponse();
            } else {
                const token = users.data.access_token;
                const IdToken = users.data.id_token;
                localStorage.setItem('token',users.data.access_token);
                localStorage.setItem('defaultToken',users.data.access_token);
                const defaultToken = localStorage.getItem('defaultToken',users.data.access_token);
                const user = jwt(token);

                // This code is just for correction purpose of the incorrect spelling in the dev environment
                if(user.PostionName != null && user.PositionName == null) {
                    user.PositionName = user.PostionName
                }
                localStorage.setItem('idToken', IdToken)
                const idInfo = jwt(IdToken);
                const defaultUser = jwt(defaultToken);
                const defaultIdInfo = jwt(defaultToken);
                const defaultUserInfo = {...defaultUser, ...defaultIdInfo}
                let companyCode = user.amrcompany === 'PT' || user.amrcompany === 'MQ' ? 'AE' : user.amrcompany;
                localStorage.setItem('token_expiration',user.exp);
                const tokenDetails ={'access_token':token}
                const userInfo = {...user, ...idInfo, ...tokenDetails, amrcompany: companyCode }
                localStorage.setItem('smuser', JSON.stringify(userInfo.SM_USER));
                localStorage.setItem('tokenData', JSON.stringify(userInfo));
                localStorage.setItem('defaultTokenData', JSON.stringify(defaultUserInfo));
                localStorage.setItem('showChangeStation', JSON.stringify(false));
                localStorage.setItem('userId', '');
                yield put(actions.setAuthToken(token, userInfo.SM_USER, userInfo.SM_STATION, userInfo.amrcompany));
                yield put(actions.authenticateSuccess(userInfo));
                yield put(actions.initialDataDownload(trimUserId(userInfo.SM_USER), "D_224097", userInfo.SM_STATION, " ", " ", "Desktop", " ", userInfo.amrcompany, " ", " ", " "))
                if (companyCode == 'AA') {
                    yield put(scorecardActions.getUserScore({employeeId:userInfo.SM_USER,stationCode:userInfo.SM_STATION} ));
                }
            }
        } catch (error) {
            clearAuthResponse();
        }
    } else {
        yield put(
        actions.authenticateSuccess(JSON.parse(params.tokenData))
        );
    }
  }

  export function* saveUserInfoFromToken(token, IdToken) {
    const user = jwt(token);
    const idInfo = jwt(IdToken);
    localStorage.setItem('token_expiration',user.exp);
    const tokenDetails ={'access_token':token}
    const userInfo = {...user, ...idInfo, ...tokenDetails }
    localStorage.setItem('smuser', JSON.stringify(userInfo.SM_USER))
    localStorage.setItem('tokenData', JSON.stringify(userInfo))
    yield put(actions.authenticateSuccess(userInfo));
  }

  export function* clearAuthResponse(action) {
    const idToken = localStorage.getItem('idToken');  
      try {
        yield call(logOff, action.userId, action.stationCode, action.companyCode, 'Desktop');
        yield localStorage.clear();
        // yield window.location.href = resources.logoutUrl;
        console.log("------->",idToken);
        yield window.location.href = resources.logoutUrl.replace('${IDTOKEN}', idToken); 
      } catch (error){
        yield localStorage.clear();
        // yield window.location.href = resources.logoutUrl;
        yield window.location.href = resources.logoutUrl.replace('${IDTOKEN}', idToken); 
      }
  }

    export function* initialData(action){
        for(let i = 0; i < 3; i++) {
            try {
                const response = yield call(
                    initialDataDownload, 
                    action.userId,
                    action.phoneNumber,
                    action.stationCode,
                    action.policyType="",
                    action.policyAccepted="",
                    action.deviceType,
                    action.deviceId="",
                    action.companyCode,
                    action.workArea="",
                    action.fcmToken="",
                    action.adminUserId=""
                )
                if(response.data.errorResponseDTO.errorCode === 0 || response.data.errorResponseDTO.errorCode=== 1023){
                    yield put(actions.initialDataDownloadSuccess(response.data))
                    if(response.data.displayRovrTour !== undefined) {
                        yield put(openTourOnLogIn(response.data.displayRovrTour))
                    }
                } else {
                    yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                }
                break;
            }
            catch (error) {
                if(i < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                    yield delay(5000);
                } else {
                    yield put(actions.initialDataError());
                    if(error.response) {
                        yield put(erroractions.showErrorAction({
                            errorCode: error.response.status,
                            errorMessage: 'Error Message'
                        }));
                    } else {
                        yield put(erroractions.showErrorAction({
                            errorCode: 'cancelled',
                            errorMessage: 'Error Message'
                        }));
                    }
                    break;
                }
            }
        }
    }
    
  export function* loginAuth(){
    try {
        window.location.href = resources.loginUrl;
        yield null;
    }
    catch (error) {
        yield put(actions.loginAuthError());
    }
  }


  export function* setAuthToken(action){
    try {
        yield call(setAuthorizationToken, action.accessToken, action.userId, action.station, action.company, resources.AZURE_BASE_URL, resources.SCORECARD_BASE_URL, resources.CLOUD_BASE_URL);
    }
    catch (error) {
        yield put(actions.setAuthTokenError());
    }
  }
  

  

  