import { 
    MY_FLIGHT_SEARCH_DATA_SUCCESS,
    MY_FLIGHT_SEARCH,
} from './MyFlightsConstants'
import * as erroractions from "../error/ErrorAction";


export const myFlightsSearchAction = (stationCode = '', department = '', gateName = []) => ({
    type: MY_FLIGHT_SEARCH,
    stationCode,
    department,
    gateName,
});

export const fetchFlightsDataSuccess = (response) => ({
    type: MY_FLIGHT_SEARCH_DATA_SUCCESS,
    data: response
})
