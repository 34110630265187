// export const BASE_URL = 'https://dm-service-rt.apps.sepaas.aa.com/dynamicManning/v2/';
// export const BASE_URL = 'https://dm-service-rt-rewrite.apps.sepaas.aa.com/dynamicManning/v2/';

import resources from '../resources/resources.json';

export const BASE_URL = resources.BASE_URL;
export const AZURE_BASE_URL = resources.AZURE_BASE_URL;
export const API_SCORECARD = resources.SCORECARD_BASE_URL;
export const CLOUD_BASE_URL = resources.CLOUD_BASE_URL;

export const GET_ALL_DATA_USER = 'getAllDataForUser'

export const CREW_INFO = 'crewinfo'

export const START_TASK = 'startTask'

export const ACKNOWLEDGE_RECEIPT = 'acknowledgeReceipt'

export const COMPLETE_TASK = 'completeTask'

export const STATION_GATES = 'getStationGates'

export const ZONE_LIST_BY_STATION = 'getZoneListByStation'

export const GET_TASK_BY_TIMELINE = 'getTaskByTimeline'

export const GET_STATION_LIST = 'getStationList'

export const GET_TASK_DATA_BY_SEARCH = 'getTaskDataBySearch'

export const ACCEPT_POLICY = 'acceptPolicy'

export const FEEDBACK = 'feedback'

export const CODE_113_ALERT = 'sendgroupalertsms'

export const FAQ = 'faq'

export const REGISTER = 'register'

export const SET_AUTHORIZATION_TOKEN = 'setAuthorizationToken'

export const BROADCAST_MESSAGES = 'broadcastmessages'

export const LOGOFF = 'logoff'

export const SEARCH_DEFAULT = 'search/defaults'

export const SEARCH_DEFAULT_DELETE = 'search/defaults/delete'

export const SHIFTS = 'shifts'

export const SUBWORK_AREAS = 'subworkareas'

export const TEAMS = 'teams'

export const SET_TASK_ROLES = "taskroles"

export const GET_TASK_HISTORY = "getTaskHistory"

export const CREATE_ADHOC_TASK_POST = "task/adhoc"

export const ACCEPT_ROVR_TOUR = "acceptRovrTour"

export const GET_TIP = "getTips"

export const SCORECARD_REPORT = "getScoreCardReport"

export const GET_SCORE_DASHBOARD = 'getScoreDashboard'

export const GET_FLIGHT_DETAILS = "getEmployeeFlightDashboard"

export const GET_FLIGHTS = 'flights'

export const GET_PREVIOUS_TASKS = 'employee/previoustask'