import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./../details/detailsAction";
import FlightArrivalIcon from "../../assets/images/Web_FlightDeparture_Icon.svg";
import { FaLocationDot } from "react-icons/fa6";
class PreviousTaskContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      previousTasks: {},
      previousTasksArr:[],
      update: props.update,
    };
  }
   componentDidMount() {
     this.fetchPreviousTasks();
  }

  async componentDidUpdate(nextProps, prevProps) {
    await this.state.update(this.props.previousTasks)
    if(prevProps.previousTasks !== nextProps.data.previousTask){
      this.setState({
        previousTasks: nextProps.data.previousTask
      });
    }
  }

  fetchPreviousTasks = () => {
    return this.props.action.fetchPreviousTasks(
      this.props.data.employeeId,
      this.props.data.taskId,
      this.props.fetchDetailsParams.station,
      this.props.fetchDetailsParams.company
    );
  };

  render() {
    return (
     <div className="border border-dark border-1"
        style={{
          background: "#e4e9ed",
          color: "#36495a",
          height: 150,
          padding: 20,
          margin: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignContent: "center",

        }}
      >
        <div
          style={{
            height: 80,
            width: 200,
            padding: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{ fontSize: 15 }}>
            EE # {this.state.previousTasks !== undefined ? this.state.previousTasks.userId : "NA"}
          </div>
          <div style={{ fontWeight: "bold", fontSize: 15, marginTop: 10 }}>
            Shift Time:
          </div>
          <div>
            {this.state.previousTasks !== undefined && this.state.previousTasks !== null &&
              this.state.previousTasks.shiftData !== undefined && this.state.previousTasks.shiftData !== null
              ? this.state.previousTasks.shiftData.shiftStartTime.substr(10)
              : "NA"}{" "}
            -{" "}
            {this.state.previousTasks !== undefined && this.state.previousTasks !== null &&
              this.state.previousTasks.shiftData !== undefined && this.state.previousTasks.shiftData !== null
              ? this.state.previousTasks.shiftData.shiftEndTime.substr(10)
              : "NA"}
          </div>
        </div>
        <div
          style={{
            height: 80,
            width: 200,
            padding: 10,
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ fontWeight: "bold" }}>Previous Task</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              {this.state.previousTasks !== undefined && this.state.previousTasks !== null
                ? this.state.previousTasks.taskDisplayName !== null || undefined ? this.state.previousTasks.taskDisplayName
                : "NA" : "NA"}
            </div>
            <div>
              <img
                src={FlightArrivalIcon}
                alt="Split Role"
                style={{ height: 25, width: 25, marginLeft: 10 }}
              />
            </div>
          </div>

          <div >
            {this.state.previousTasks !== undefined && this.state.previousTasks !== null ? this.state.previousTasks.taskLocation : "NA"}
            <FaLocationDot style={{paddingLeft: 5}}/>
          </div>
          <div>
            {this.state.previousTasks !== undefined && this.state.previousTasks !== null ? this.state.previousTasks.startTime : "NA"} - {" "}
            {this.state.previousTasks !== undefined && this.state.previousTasks !== null ? this.state.previousTasks.endTime : "NA"}
            {/* 03:00 - 09:00 */}
          </div>
          <div style={{ fontWeight: "bold" }}>FINISHED</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  previousTasks: state.detailsReducer.previousTasks,
  previousTasksArr: state.detailsReducer.previousTasksArr,
});

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators({ ...actions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviousTaskContainer);
