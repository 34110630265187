import * as actions from './MyFlightsConstants'
const initialState = {
    searchFlights: [],
}

const MyFlightsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.MY_FLIGHT_SEARCH_DATA_SUCCESS: {
            return {
                ...state,
                searchFlights: action.data,
            }
        }
        default:
            return state;
    }
}

export default MyFlightsReducer;
